/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Form,
  InputField,
  Label,
  SelectStyle,
} from '../../../styles/common/Form';
import { useForm, Controller } from 'react-hook-form';
import { getAllTeachers } from '../../../faunadb/queries/teacher';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { createCourse } from '../../../faunadb/queries/course';
import { getDateInBetweenWithWeekDay } from '../../../faunadb/helpers/utils';
import moment from 'moment';
import { GET_TERM, getTerm } from '../../../faunadb/queries/term';
import { ALL_DISCOUNT_GROUP, getAllDiscountGroups } from '../../../faunadb/queries/setting';
import { Button, DatePicker, Row, Table } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { useQuery as useReactQuery, useMutation as useReactMutation, useQueryClient } from 'react-query';
import { useFaunaContext } from '../../../providers/FaunaProvider';
const CourseForm = ({ term, close }) => {
  const history = useHistory();
  const [classList, setClassList] = useState([]);

  const { data: termData } = useReactQuery(['term', term], async () => await getTerm(client, term));

  const { register, handleSubmit, control, watch } = useForm();
  const { client } = useFaunaContext();
  const { data } = useReactQuery('allTeachers', async () => await getAllTeachers(client))
  const { data: discounts } = useReactQuery('allDiscountGroups', async () => await getAllDiscountGroups(client));

  const createCourseMutation = useReactMutation((data) => createCourse(client, data), {
    onSuccess: () => {
      history.goBack();
    }
  });

  let defaultDiscount;

  let teacherOptions = [];
  let discountOptions = [];
  let priceOptions = [
    {
      label: '1720',
      value: 1720,
    },
    {
      label: '2380',
      value: 2380,
    },
  ];
  let weekdayOptions = [
    {
      label: 'Monday',
      value: 1,
    },
    {
      label: 'Tuesday',
      value: 2,
    },
    {
      label: 'Wednesday',
      value: 3,
    },
    {
      label: 'Thursday',
      value: 4,
    },
    {
      label: 'Friday',
      value: 5,
    },
    {
      label: 'Saturday',
      value: 6,
    },
    {
      label: 'Sunday',
      value: 7,
    },
  ];

  let nameOptions = [
    { label: 'Intro', value: 'Intro' },
    { label: 'Pole Style 1', value: 'Pole Style 1' },
    { label: 'Pole Style 2', value: 'Pole Style 2' },
    { label: 'Exotica', value: 'Exotica' },
    { label: 'Silky Flow', value: 'Silky Flow' },
    { label: 'Asian Twist 1', value: 'Asian Twist 1' },
    { label: 'Asian Twist 2', value: 'Asian Twist 2' },
    { label: 'Taboo', value: 'Taboo' },
    { label: 'Level 1', value: 'Level 1' },
    { label: 'Level 1.5', value: 'Level 1.5' },
    { label: 'Level 2', value: 'Level 2' },
    { label: 'Level 2.5', value: 'Level 2.5' },
    { label: 'Bendy', value: 'Bendy' },
    { label: 'Practice', value: 'Practice' },
    { label: 'Artistica', value: 'Artistica' },
    { label: 'Chair', value: 'Chair' },
  ];
  if (data?.data) {
    for (let i = 0; i < data.data.length; i++) {
      if (data.data[i].deleted !== true) {
        teacherOptions.push({
          value: data.data[i].ref.value.id,
          label: data.data[i].data.name,
        });
      }
    }
  }

  if (discounts?.data) {
    for (let i = 0; i < discounts.data.length; i++) {
      if (discounts.data[i].data.name === 'No Discount') {
        defaultDiscount = discounts.data[i].ref.value.id;
      }
      console.log(discounts.data[i].data?.deleted);
      if (discounts.data[i].data?.deleted !== true) {
        discountOptions.push({
          value: discounts.data[i].ref.value.id,
          label: discounts.data[i].data.name,
        });
      }
    }
  }

  const weekDayWatch = watch('weekday');
  const timeWatch = watch('time');

  useEffect(() => {
    if (weekDayWatch && timeWatch) {
      let dateMatch = getDateInBetweenWithWeekDay(
        termData?.start_date.value,
        termData?.end_date.value,
        timeWatch,
        weekDayWatch.label
      );
      let withInBreaks = [];
      if (termData) {
        console.log(termData);
        if (
          termData?.break_start_date && termData?.break_start_date?.value &&
          termData?.break_end_date && termData?.break_end_date?.value
        ) {
          for (let i = 0; i < dateMatch.length; i++) {
            if (
              moment(dateMatch[i]).diff(
                moment(termData?.break_start_date.value),
                'hour'
              ) >= 0 &&
              moment(dateMatch[i]).diff(
                moment(termData?.break_end_date.value),
                'hour'
              ) <= 24
            ) {
              console.log('with-in break');
              withInBreaks.push(dateMatch[i]);
            }
          }
        }
      }
      let dateMatchObjectList = [];
      // eslint-disable-next-line array-callback-return
      dateMatch.map((d) => {
        if (!withInBreaks.includes(d)) {
          dateMatchObjectList.push({
            time: d,
          });
        }
      });
      setClassList(dateMatchObjectList);
    }
  }, [weekDayWatch, timeWatch]);

  const onSubmit = (data) => {
    if (data.teacher === '' || data.weekday === '') return;
    let classes = [];
    classList.forEach((item) => {
      classes.push({
        date: item.time,
        end_date: moment(item.time).add(data.duration, 'minute').toISOString(),
      });
    });
    createCourseMutation.mutate({
      name: data.name.value,
      price: parseInt(data.price.value),
      quota: parseInt(data.quota),
      teacher: data.teacher.value,
      term: term,
      weekday: data.weekday.value,
      classes: classes,
      start_at: data.time,
      duration: parseInt(data.duration),
      discount_group: data.discount_group.value || defaultDiscount,
    })
    // createCourse({
    //   variables: {
    //     name: data.name.value,
    //     price: parseInt(data.price.value),
    //     quota: parseInt(data.quota),
    //     teacher: data.teacher.value,
    //     term: term,
    //     weekday: data.weekday.value,
    //     classes: classes,
    //     start_at: data.time,
    //     duration: parseInt(data.duration),
    //     discount_group: data.discount_group.value || defaultDiscount,
    //   },
    // }).then((value) => {
    //   history.goBack();
    // });
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Label>Course Name</Label>
        <Controller
          as={<CreatableSelect styles={SelectStyle} />}
          options={nameOptions}
          name="name"
          isClearable
          control={control}
          defaultValue=""
        />
        <Label>Teacher</Label>
        <Controller
          as={<ReactSelect styles={SelectStyle} />}
          options={teacherOptions}
          name="teacher"
          isClearable
          control={control}
          defaultValue=""
        />
        <Label>Weekday</Label>
        <Controller
          as={<ReactSelect styles={SelectStyle} />}
          options={weekdayOptions}
          name="weekday"
          isClearable
          control={control}
          defaultValue=""
        />
        <Label>Time</Label>
        <InputField
          name="time"
          pattern="[0-9]{2}:[0-9]{2}"
          ref={register({ required: true })}
        ></InputField>
        {classList.length > 0 && (
          <>
            <Table
              showHeader={false}
              style={{ width: '100%' }}
              autoHeight
              data={classList}
            >
              <Table.Column flexGrow={1}>
                <Table.HeaderCell>Class Time</Table.HeaderCell>
                <Table.Cell>
                  {(rowData) => {
                    return moment(rowData.time).format('YYYY MMM DD HH:mm');
                  }}
                </Table.Cell>
              </Table.Column>

              <Table.Column flexGrow={1} fixed="right">
                <Table.HeaderCell>Action</Table.HeaderCell>

                <Table.Cell>
                  {(rowData, index) => {
                    function handleAction() {
                      console.log(rowData, index);
                      // eslint-disable-next-line array-callback-return
                      const temp = classList.filter((item, i) => {
                        if (i !== index) return item;
                      });
                      setClassList(temp);
                    }
                    return (
                      <span>
                        <Button appearance="link" onClick={handleAction}>
                          Remove{' '}
                        </Button>
                      </span>
                    );
                  }}
                </Table.Cell>
              </Table.Column>
            </Table>
            {console.log(classList)}
            <Row>
              <DatePicker
                format="yyyy-MM-dd HH:mm"
                onChange={(value) => {
                  classList.push({
                    time: moment(value)
                      .set({ second: 0, millisecond: 0 })
                      .toISOString(),
                  });
                  const temp = classList.filter((item) => {
                    return item;
                  });
                  setClassList(temp);
                  // moment(value).toISOString();
                }}
                defaultValue={new Date(classList[classList.length - 1].time)}
              />
            </Row>
          </>
        )}
        <Label>Duration (Min)</Label>
        <InputField
          name="duration"
          type="number"
          step="1"
          minLength={5}
          maxLength={5}
          ref={register({ required: true })}
        ></InputField>
        <Label>Quota</Label>
        <InputField
          name="quota"
          type="number"
          ref={register({ required: true })}
        />
        <Label>Price</Label>
        <Controller
          as={<CreatableSelect styles={SelectStyle} />}
          options={priceOptions}
          name="price"
          isClearable
          control={control}
          defaultValue=""
        />
        <Label>Discount Group</Label>
        <Controller
          as={<ReactSelect styles={SelectStyle} maxMenuHeight={100} />}
          options={discountOptions}
          name="discount_group"
          isClearable
          control={control}
          defaultValue=""
        />
        {/* <InputField
          name="price"
          type="number"
          ref={register({ required: true })}
        /> */}
        <Label>Submit</Label>
        {!createCourseMutation.isLoading && <InputField type="submit" />}
      </Form>
    </>
  );
};

export default CourseForm;
